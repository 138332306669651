<template>
  <div id="faq" class="faq">
    <h1>{{ $t('faq.heading') }}</h1>

    <button class="collapsible">
      {{ $t('faq.collapse_1_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_1_content') }}</p>
    </div>

    <button class="collapsible">
      {{ $t('faq.collapse_2_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_2_content') }}</p>
    </div>

    <button class="collapsible">
      {{ $t('faq.collapse_3_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_3_content') }}</p>
    </div>

    <button class="collapsible">
      {{ $t('faq.collapse_4_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_4_content') }}</p>
    </div>

    <button class="collapsible">
      {{ $t('faq.collapse_5_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_5_content') }}</p>
    </div>

    <button class="collapsible">
      {{ $t('faq.collapse_6_title') }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t('faq.collapse_6_content') }}</p>
    </div>
    <br><br>
    <h1 id="partner_small">
      Learn about our
      <router-link @click="routeToHome()" :to="{ path: '/partner' }">{{
          $t("Header[10]")
      }}</router-link>
    </h1>
  </div>
</template>

<script>
export default {
  methods: {
    routeToHome: function () {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  }
}
</script>

<style scoped>
#faq:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #faq:target {
    padding-top: 15vh;
  }
}

@media screen and (min-width: 991px) {
  #partner_small {
    display: none;
  }
}
</style>