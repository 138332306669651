<template>
  <div id="partner">
    <!-- title -->
    <div id="partner_title">{{ $t("Partners.header") }}</div>
    <br><br><br><br><br><br><br><br>
    <div id="partners_slides">
      <!-- Platinum Investors -->
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[0].imgs" :key="i" :image="slide" :title="slides[0].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Gold Investors -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[1].imgs" :key="i" :image="slide" :title="slides[1].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Educational Institution Partners -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[2].imgs" :key="i" :image="slide" :title="slides[2].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Healthcare Institution Partners -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[3].imgs" :key="i" :image="slide" :title="slides[3].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Platinum Partners / Collaborators -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[4].imgs" :key="i" :image="slide" :title="slides[4].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Gold Partners / Collaborators -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[5].imgs" :key="i" :image="slide" :title="slides[5].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Platinum Suppliers / Vendors -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[6].imgs" :key="i" :image="slide" :title="slides[6].title">
        </vueper-slide>
      </vueper-slides>
      <!-- Gold Suppliers / Vendors -->
      <br><br>
      <vueper-slides fixed-height="200px" class="slides" slide-content-outside="top" :visible-slides="5" slide-multiple
        :gap="3">
        <vueper-slide v-for="(slide, i) in slides[7].imgs" :key="i" :image="slide" :title="slides[7].title">
        </vueper-slide>
      </vueper-slides>
      <br><br><br>
    </div>

  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: { VueperSlides, VueperSlide },
  computed: {
    slides() {
      var slides = [
        {
          title: this.$t("Partners.platInvestor"),
          imgs: [require("../img/partners/null.png")]
        },
        {
          title: this.$t("Partners.goldInvestor"),
          imgs: [require("../img/partners/null.png")]
        },
        {
          title: this.$t("Partners.EdInstPartners"),
          imgs: [require("../img/partners/hslu_logo.png")]
        },
        {
          title: this.$t("Partners.HealthInstPartners"),
          imgs: [require("../img/partners/null.png")]
        },
        {
          title: this.$t("Partners.platPart&Collab"),
          imgs: [require("../img/partners/null.png")]
        },
        {
          title: this.$t("Partners.goldPart&Collab"),
          imgs: [require("../img/partners/piecapital_logo.png"), require("../img/partners/actionspot_logo.png")]
        },
        {
          title: this.$t("Partners.platSupply&Vendors"),
          imgs: [require("../img/partners/null.png")]
        },
        {
          title: this.$t("Partners.goldSupply&Vendors"),
          imgs: [require("../img/partners/nvidia_logo.png")]
        },
      ];
      return slides;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    window.onload = function () {
      var bullets = document.getElementsByClassName("vueperslides__bullet")
      for (var i = 0; i < bullets.length; i++) {
        bullets[i].style.backgroundColor = "gray"
      }
    }
  },
};
</script>

<style scoped>
#partner_title {
  background-color: orange;
  color: black;
  position: absolute;
  top: 43px;
  left: 0px;
  width: 100%;
  height: 200px;
  font-size: 35px;
  padding-left: 70px;
  padding-top: 70px;
}

#partners_slides {
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
}
</style>
