<template>
  <div id="research" class="research">
    <!-- partner side -->
    <div id="partner">
      <div id="plati_investor" class="partner_content">
        <div class="partner_title">Platinum Investors</div>
        <div class="partner_names">Evaluation in Progress</div>
      </div>
      <br />
      <div id="gold_investor" class="partner_content">
        <div class="partner_title">Gold Investors</div>
        <div class="partner_names">Evaluation in Progress</div>
      </div>
      <br />
      <div id="edu" class="partner_content">
        <div class="partner_title">Educational Institution Partners</div>
        <div class="partner_names">
          <img src="../img/partners/hslu_logo.png" alt="hslu" height="110px">
        </div>
      </div>
      <br />
      <div id="health" class="partner_content">
        <div class="partner_title">Healthcare Institution Partners</div>
        <div class="partner_names">Evaluation in Progress</div>
      </div>
      <br />
      <div id="plati_partner" class="partner_content">
        <div class="partner_title">Platinum Partners / Collaborators</div>
        <div class="partner_names">Evaluation in Progress</div>
      </div>
      <br />
      <div id="gold_partner" class="partner_content">
        <div class="partner_title">Gold Partners / Collaborators</div>
        <div class="partner_names">
          <img src="../img/partners/piecapital_logo.png" alt="piecapital" height="90px">
          <img src="../img/partners/actionspot_logo.png" alt="actionspot" height="90px">
        </div>
      </div>
      <br />
      <div id="plati_sup" class="partner_content">
        <div class="partner_title">Platinum Suppliers / Vendors</div>
        <div class="partner_names">Evaluation in Progress</div>
      </div>
      <br />
      <div id="gold_sup" class="partner_content">
        <div class="partner_title">Gold Suppliers / Vendors</div>
        <div class="partner_names">
          <img src="../img/partners/nvidia_logo.png" alt="nvidia_logo" height="90px">
        </div>
      </div>
    </div>
    <!-- title -->
    <h1>{{ $t("Research") }}</h1>

    <!-- shown button 1 -->
    <button class="collapsible">
      {{ $t("Research_collapse_1[0]") }}
    </button>

    <div class="collapsible_content">
      <div class="cards">
        <!-- card 1.1 -->
        <div class="card">
          <!-- img 1.1 -->
          <div class="card-image">
            <img src="../img/chart_childrenUS.png" class="image" alt="chart_childrenUS" />
          </div>

          <!-- title 1.1 -->
          <div class="card-title">
            {{ $t("Research_collapse_2[0]") }}
          </div>

          <!-- content 1.1 -->
          <div class="card-text">
            {{ $t("Research_collapse_2[1]") }}
          </div>
        </div>

        <!-- card 1.2 -->
        <div class="card">
          <!-- img 1.2 -->
          <div class="card-image">
            <img src="../img/earlierintervention2.png" class="image" alt="earlierintervention2" />
          </div>

          <!-- title 1.2 -->
          <div class="card-title">
            {{ $t("Research_collapse_4[0]") }}
          </div>

          <!-- content 1.2 -->
          <div class="item_content">
            <p>
              <i>{{ $t("Research_collapse_4[1]") }}</i><br />
              {{ $t("Research_collapse_4[2]") }}
            </p>
            <p>{{ $t("Research_collapse_4[3]") }}</p>
            <p>
              {{ $t("Research_collapse_4[4]") }}<br />
              {{ $t("Research_collapse_4[5]") }}<br />
              {{ $t("Research_collapse_4[6]") }}<br />
              {{ $t("Research_collapse_4[7]") }}<br />
              {{ $t("Research_collapse_4[8]") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- shown button 2 -->
    <button class="collapsible">
      {{ $t("Research_collapse_3[0]") }}
    </button>
    <!-- content 2 -->
    <div class="collapsible_content">
      <!-- title 2.1 -->
      <p>
        <b>{{ $t("Research_collapse_3[1]") }}</b>
      </p>
      <!-- content 2.1 -->
      <p>{{ $t("Research_collapse_3[2]") }}</p>
      <li>{{ $t("Research_collapse_3[3]") }}</li>
      <li>{{ $t("Research_collapse_3[4]") }}</li>
      <li>{{ $t("Research_collapse_3[5]") }}</li>
      <li>{{ $t("Research_collapse_3[6]") }}</li>
      <li>{{ $t("Research_collapse_3[7]") }}</li>
      <p>{{ $t("Research_collapse_3[8]") }}</p>

      <!-- title 2.2 -->
      <b>{{ $t("Research_collapse_3[9]") }}</b>
      <!-- content 2.2 -->
      <p>{{ $t("Research_collapse_3[10]") }}</p>

      <p>
        <!-- title 2.3 -->
        <b>{{ $t("Research_collapse_3[11]") }}</b><br />
        <!-- content 2.3 -->
        <img src="../img/research_traditionalprocess.png" width="80%" alt="research_traditionalprocess" />
      </p>

      <p>
        <!-- title 2.4 -->
        <b>{{ $t("Research_collapse_3[12]") }}</b><br />
        <!-- content 2.4 -->
        <img src="../img/research_nlprocess.png" width="80%" alt="research_nlprocess" />
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#research:target {
  padding-top: 9vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #research:target {
    padding-top: 15vh;
  }
}

#partner {
  background-color: white;
  position: absolute;
  left: 6px;
  width: 16%;
  height: auto;
}

.partner_title {
  background-color: orange;
  border-radius: 6px 6px 0px 0px;
  text-align: center;
}

.partner_names {
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  border-color: black;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
}

.research {
  width: 100%;
}

.cards {
  display: flex;
  justify-content: center;
}

.card {
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 350px;
  height: auto;
  margin: 10px;
  padding: 15px;
}

.card-title {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image {
  width: 100%;
}

.chart {
  height: 300px;
}

@media screen and (max-width: 800px) {
  .cards {
    display: block;
    width: auto;
  }

  .card {
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  #partner {
    display: none;
  }
}
</style>
