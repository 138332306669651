<template>
  <div>
    <div id="login" class="login">
      <h1>{{ $t("Header[9]") }}</h1>
      <div class="container_login">
        <!-- content mid: login form -->
        <div class="container_mid">
          <div id="loginform">
            <h1 class="brand">
              <span>{{ $t("newsletterform.neuro") }}</span>
              {{ $t("newsletterform.leap") }}
            </h1>
            <div class="login_wrapper">
              <div class="loginform">
                <div class="alert">You Have Successfully Logged In!</div>
                <form id="loginform">
                  <p>
                    <label for="username">{{
                      $t("authentication.username")
                    }}</label>
                    <input
                      v-model="input.username"
                      name="username"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !username }"
                      placeholder="Username"
                    />
                  </p>
                  <div v-show="submitted && !username" class="invalid-feedback">
                    Username is required.
                  </div>
                  <p>
                    <label for="password">{{
                      $t("authentication.password")
                    }}</label>
                  </p>
                  <p>
                    <input
                      v-model="input.password"
                      name="password"
                      type="password"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !password }"
                      placeholder="Password"
                    />
                  </p>

                  <div v-show="submitted && !password" class="invalid-feedback">
                    Password is required.
                  </div>
                  <p>
                    <button type="button" @click="login()">Login</button>
                  </p>

                  <div id="fgtPassContainer">
                    <a v-b-modal.modal-center id="fgtPass">Forget Password</a>
                    <b-modal
                      id="modal-center"
                      centered
                      hide-footer
                      title="Please Enter Recovery Email"
                    >
                      <input
                        placeholder="Email"
                        required
                        v-model="recoveryEmail"
                      />
                      <button
                        @click="validateEmail"
                        :disabled="isButtonDisabled"
                      >
                        Submit
                      </button>
                      <span
                        id="notifyValidEmail"
                        :class="!valid ? 'warning' : 'sucess'"
                      ></span>
                      {{ buttonText }}
                    </b-modal>
                  </div>

                  <p>
                    <router-link to="/register" class="btn btn-link">
                      <button type="button">Go to Registration Page</button>
                    </router-link>
                  </p>
                  <br />

                  Or
                  <br />
                  <p>Login as Event Participant</p>
                  <p>
                    <label for="par_firstname">Firstname:</label>
                  </p>
                  <p>
                    <input type="text" v-model="par_firstname" />
                  </p>
                  <p>
                    <label for="par_lastname">Lastname:</label>
                  </p>
                  <p>
                    <input type="text" v-model="par_lastname" />
                  </p>
                  <p>
                    <label for="par_email">Email:</label>
                  </p>
                  <p>
                    <input type="text" v-model="par_email" />
                  </p>
                  <p>
                    <button @click="loginParticipant">Submit</button>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import dataService from "../services/dataService";
// import axios from "axios";
import instance from "../axios";

export default {
  name: "Login",

  mounted() {
    var logged = this.$store.getters.isLoggedIn;
    // console.log(logged);
    return logged;
  },

  data() {
    return {
      valid: false,
      input: {
        username: "",
        password: "",
      },
      submitted: false,
      recoveryEmail: "",
      par_firstname: "",
      par_lastname: "",
      par_email: "",
      isButtonDisabled: false,
      buttonText: "",
      countdown: 60,
    };
  },
  methods: {
    disableButton() {
      this.isButtonDisabled = true;
      this.buttonText = `Please wait ${this.countdown}s`;

      // Start countdown
      let countdownInterval = setInterval(() => {
        this.countdown--;
        this.buttonText = `Please wait ${this.countdown}s`;

        if (this.countdown === 0) {
          clearInterval(countdownInterval);
          this.isButtonDisabled = false;
          this.buttonText = "";
          this.countdown = 60; // Reset countdown
        }
      }, 1000);
    },
    //60s for each request, call backend: if email addr in DB, send email to it with link going to a new page, like user approval; on page,
    validateEmail() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var referenceEmail = document.getElementById("notifyValidEmail");
      // if email format is good, try to send email
      if (re.test(String(this.recoveryEmail).toLowerCase())) {
        this.disableButton();
        const data = {
          email: this.recoveryEmail,
        };
        this.valid = true;
        referenceEmail.innerHTML = "Email Sent";
        this.recoveryEmail = "";

        instance
          .post("api/forgetPassword", data)
          .then(function (response) {
            if (response.data.Username) {
              console.log(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.valid = false;
        referenceEmail.innerHTML = "Invalid Email";
      }
    },
    login() {
      const data = {
        username: this.input.username,
        password: this.input.password,
      };
      var tempThis = this;
      console.log(data);
      instance
        .post("api/verifylogin", data)
        .then(function (response) {
          if (response.data.Username) {
            console.log(response.data);
            tempThis.$store.commit(
              "setUserEventSignup",
              tempThis.cleanString(response.data.Registeredevents)
            );
            tempThis.$store.commit("setAuthUser", response.data.Username);
            tempThis.$store.commit("setUserLevel", response.data.Rolemanage);
            tempThis.$router.push("/");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cleanString(str) {
      if (str == "") {
        return "";
      }
      // Replace "[," with "["
      return JSON.parse(str.replace("[,", "["));
    },
    loginParticipant(event) {
      event.preventDefault();
      const data = {
        firstname: this.par_firstname,
        lastname: this.par_lastname,
        email: this.par_email,
      };
      var tempThis = this;

      instance
        .post("api/loginParticipant", data)
        .then(function (response) {
          if (response.data.Email) {
            let timestampsArray = JSON.parse(response.data.Timestamp);
            tempThis.$store.commit("setAuthUser", response.data.Email);
            tempThis.$store.commit("setEventID", timestampsArray);
            tempThis.$router.push("/");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.container_login {
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* loginform */
* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.login_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.login_wrapper > * {
  padding: 1em;
}

.loginform {
  background: #f9c75c;
}

#fgtPassContainer {
  text-align: right;
}

#fgtPass:hover {
  cursor: pointer;
  text-decoration: underline;
  color: white;
}

/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .login_wrapper {
    display: grid;
  }

  .login_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

@media screen and (max-width: 601px) {
  .container_login_wrapper {
    display: block;
  }
}
.warning {
  color: red;
  font-weight: bolder;
}

.sucess {
  color: lightgreen;
  font-weight: bolder;
}

#notifyValidEmail {
  margin-left: 10px;
}
</style>
